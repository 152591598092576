import { useMutation } from '@apollo/client';
import * as pc from 'bit-uibl-data-driver';
import React, { useEffect } from 'react';

import { ADD_CUSTOMER, GET_QUOTE, PROD_ADD_CUSTOMER } from '../helpers/queries';

function ContactCreation( { contact, contactAdded } ) {

  const { email, agent, country, homeAddress, lastName, firstName, stateCode,  stateName, zipCode, street, phoneNo, city, auth0_id, insuranceType } = contact;

  async function addToHubSpot(){
    let vid  = '';
    await pc.BackendService.hubSpotCheckEmail( JSON.stringify( email ) ).then( response => {
      if ( response.Payload ) {
        const data = JSON.parse( response.Payload );
        console.log( data, 'response' );
        if ( data.errorType ) {
          vid = null;
        } else {
          vid = data.body.response.data.vid;
        }
        const postBody = {
          firstname: firstName,
          lastname: lastName,
          phone: phoneNo,
          email,
          city: city,
          zip: zipCode,
          agent_name: agent?.full_name || ( ( agent?.first_name || '' ) + ' ' + ( agent?.last_name || '' ) ),
          agent_portal_agency: agent?.company,
          full_address: homeAddress,
          state: stateName,
          home_quote_amount: quoteData.home_quote,
          interest_type: insuranceType == 'Both' ? 'Home+Life' : insuranceType
        };

        if ( vid ) {
          postBody['vid'] = vid;
          const postBodyString = JSON.stringify( postBody );
          pc.BackendService.hubSpotUpdate( postBodyString ).then( data => {
            if ( data.Payload ) {
              console.log( data.Payload );
            }
          } );
        }

        if ( !vid ) {
          const postBodyString = JSON.stringify( postBody );
          pc.BackendService.hubSpotSync( postBodyString )
            .then( data => {
              if ( data.Payload ) {
                console.log( data.Payload );
              } }
            );
        }
      }
    }
    );
  }

  const [addCustomer, { data, loading, error }] = useMutation( process.env.GATSBY_ENV == 'PROD' ? PROD_ADD_CUSTOMER : ADD_CUSTOMER );
  const [getQuickQuote, { dataQuote, loadingQuote, errorQuote }] = useMutation( GET_QUOTE );

  let quoteData = {
    cta_url: '',
    home_quote: ''
  };

  async function getQuote(){
    if( email ){
      getQuickQuote( {
        variables: {
          city: city,
          country: country,
          email: email, first_name:
        firstName,
          formatted_address:
        homeAddress,
          insurance_status: 'lead',
          last_name: lastName,
          phone: phoneNo,
          state: stateCode,
          street: street,
          zip: zipCode
        }
      } ).then( res => {
        quoteData = {
          cta_url: res.data?.quickQuote?.home?.[0]?.cta_url || '',
          home_quote: res.data?.quickQuote?.home?.[0]?.monthly_quote_amt || '0'
        };

        let insurance_types = insuranceType?.toLowerCase();
        if( insuranceType == 'Both' ){
          insurance_types = ['home', 'life'];
        } else {
          insurance_types = [insurance_types];
        }

        addToHubSpot();

        addCustomer( {
          variables: {
            auth0_id: auth0_id,
            address: homeAddress,
            email,
            first_name: firstName,
            last_name: lastName,
            full_name: `${firstName} ${lastName}`,
            insurance_types: JSON.stringify( insurance_types ),
            phone: phoneNo,
            city: city,
            stateCode: stateCode,
            street_address: street,
            cta_url: quoteData.cta_url,
            home_premium: quoteData.home_quote,
            zip: '' + zipCode
          }
        } ).then( ( res ) => {
          contactAdded( {
            auth0_id: auth0_id,
            address: homeAddress,
            email,
            first_name: firstName,
            last_name: lastName,
            full_name: `${firstName} ${lastName}`,
            insurance_types: JSON.stringify( insurance_types ),
            phone: phoneNo,
            city: city,
            stateCode: stateCode,
            street_address: street,
            cta_url: quoteData.cta_url,
            home_premium: quoteData.home_quote,
            zip: zipCode } );
        } );
      }
      );
    }
  }

  useEffect( () => { getQuote(); }, [email] );

  return(
    <>
    </>
  );
}

export default ContactCreation;
