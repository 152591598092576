
import useAutocomplete from '@mui/material/useAutocomplete';
import { styled } from '@mui/system';
import * as React from 'react';
import { useState } from 'react';

const Label = styled( 'label' )( {
  display: 'block'
} );

const Input = styled( 'input' )( ( { theme } ) => ( {
  width: 200,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  color: theme.palette.mode === 'light' ? '#000' : '#fff'
} ) );

const Listbox = styled( 'ul' )( ( { theme } ) => ( {
  'width': 200,
  'margin': 0,
  'padding': 0,
  'zIndex': 1,
  'position': 'absolute',
  'listStyle': 'none',
  'backgroundColor': theme.palette.mode === 'light' ? '#fff' : '#000',
  'overflow': 'auto',
  'maxHeight': 200,
  'border': '1px solid rgba(0,0,0,.25)',
  '& li.Mui-focused': {
    backgroundColor: '#4a8df6',
    color: 'white',
    cursor: 'pointer'
  },
  '& li:active': {
    backgroundColor: '#2977f5',
    color: 'white'
  }
} ) );

export default function UseAutocomplete( { customerList, updateValue } ) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions
  } = useAutocomplete( {
    id: 'use-autocomplete-demo',
    options: customerList,
    getOptionLabel: ( option ) => option.full_name
  } );

  const [width, setWidth] = React.useState( '500px' );
  const [searchKey, setSearchKey] = useState( '' );

  React.useEffect( () => {
    const rect = document.querySelector( '.search-parent' ).getBoundingClientRect();

    const { width } = rect;

    setWidth( width + 'px' );
  }, [] );

  const getValue = ( customer ) => {
    const selected = document.querySelector( '#use-autocomplete-demo' ).value;
    updateValue( selected?.trim() || '' );
  };


  return (
    <div>

      <div {...getRootProps()}>
        <div className='search-parent'>

          <img src="https://public-bubble.s3.us-west-2.amazonaws.com/images/search.svg" alt="search" />

          <Input
            placeholder="Advance search in Leads"
            onSelect={getValue}
            {...getInputProps()} />
        </div>
      </div>

      {groupedOptions.length > 0 ? (
        <Listbox style={{ width }} {...getListboxProps()}>
          {groupedOptions.map( ( option, index ) => (
            <li key={index} {...getOptionProps( { option, index } )} >{option.full_name}</li>
          ) )}
        </Listbox>
      ) : null}
    </div>
  );
}
