import '../styles/status.scss';

import React from 'react';

function returnBox( status ){
  switch ( status ) {
  case 'lead':
    return ( <div className='rect-box status-lead'>
          Lead
    </div> );
  case 'quote':
    return ( <div className='rect-box status-quote'>
          Quote
    </div> );
  case 'application':
    return  ( <div className='rect-box status-application'>
          Application
    </div> );

  case 'issued':
    return  ( <div className='rect-box status-issued'>
          Issued
    </div> );
  case 'denied':
    return   ( <div className='rect-box status-denied'>
          Denied
    </div> );

  default:
    break;
  }
}

export default returnBox;
