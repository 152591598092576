import '../styles/button.scss';
import '../styles/contact.scss';
import '../styles/sweet-alert.scss';

import { gql,useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import * as pc from 'bit-uibl-data-driver';
import React, { useContext,useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { geocodeByAddress } from 'react-places-autocomplete';
import Swal from 'sweetalert2';

import UserContext from '../context/context';
import { getZipCode } from '../helpers/address';
import Maps from './google';

const ADD_CUSTOMER = gql`
mutation InsertAgentportalcustomers(
  $auth0_id: String!,
  $agentid: String = "",
  $agent_email: String = "",
  $address: String = "",
  $email: String = "",
  $full_name: String = "",
  $first_name: String!,
  $last_name: String!,
  $home_premium: String = "",
  $life_premium: String = "",
  $insurance_status: String = "lead",
  $insurance_types: String = "",
  $cta_url: String!,
  $phone: String = "",
  $client_id: String = "",
  $lead_source: String = "",
  $zip: String = "",
  $city: String = "",
  $stateCode: String = "",
  $street_address: String = ""
  ) {
  insert_agentportalcustomers_one(object: {
    address: $address,
    auth0_id: $auth0_id,
    agent_email: $agent_email,
    email: $email,
    full_name: $full_name,
    client_id: $client_id,
    insurance_types: $insurance_types,
    first_name: $first_name,
    last_name: $last_name,
    home_premium: $home_premium,
    life_premium: $life_premium,
    phone: $phone,
    zip: $zip,
    lead_source: $lead_source,
    street_address: $street_address,
    statecode: $stateCode,
    city: $city,
    cta_url: $cta_url}) {
    address
    auth0_id
    agentid
    agent_email
    email
    full_name
    home_premium
    life_premium
    lead_source
    insurance_status
    insurance_types
    cta_url
    phone
    profile_url
    viewed
    zip
  }
}
`;

const PROD_ADD_CUSTOMER = gql`
mutation InsertAgentportalcustomers(
  $auth0_id: String!,
  $agentid: String = "",
  $agent_email: String = "",
  $address: String = "",
  $email: String = "",
  $full_name: String = "",
  $home_premium: String = "",
  $life_premium: String = "",
  $first_name: String!,
  $last_name: String!,
  $insurance_status: String = "lead",
  $insurance_types: String = "",
  $cta_url: String!,
  $lead_source: String = "",
  $phone: String = "",
  $city: String = "",
  $client_id: String = "",
  $stateCode: String = "",
  $street_address: String = "",
  $zip: String = ""
  ) {
    insert_agentportalcustomers_one: prod_insert_agentportalcustomers_one(object: {
    address: $address,
    auth0_id: $auth0_id,
    agent_email: $agent_email,
    email: $email,
    full_name: $full_name,
    client_id: $client_id,
    lead_source: $lead_source,
    insurance_types: $insurance_types,
    home_premium: $home_premium,
    life_premium: $life_premium,
    first_name: $first_name,
    last_name: $last_name,
    phone: $phone,
    zip: $zip,
    street_address: $street_address,
    statecode: $stateCode,
    city: $city,
    cta_url: $cta_url}) {
    address
    auth0_id
    agentid
    agent_email
    email
    full_name
    home_premium
    lead_source
    life_premium
    insurance_status
    insurance_types
    cta_url
    phone
    profile_url
    viewed
    zip
  }
}
`;

const GET_QUOTE = gql`
mutation MyMutation($city: String = "", $country: String = "", $email: String = "", $first_name: String = "", $formatted_address: String = "", $last_name: String = "", $phone: String = "", $state: String = "", $street: String = "", $zip: String = "") {
  quickQuote(arg1: {city: $city, country: $country, email: $email, first_name: $first_name, formatted_address: $formatted_address, last_name: $last_name, phone: $phone, state: $state, street: $street, zip: $zip}) {
    home {
      actual_address
      cta_url
      message
      monthly_quote_amt
      status
    }
    message
    status
    status_code
  }
}
`;

const CssTextField = styled( TextField )( {
  '& label.Mui-focused': {
    color: '#646464'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#DAE7EC'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DAE7EC'
    },
    '&:hover fieldset': {
      borderColor: '#DAE7EC'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DAE7EC'
    }
  }
} );

export default function FormDialog( { open, handleClose, addedContact, lead_source,disableBackdropClick,disableEscapeKeyDown,customerList } ) {
  const {  control, formState: { errors } } = useForm();

  const [customersToShow, updateCustomers] = useState( customerList || '' );
  const {  agent } = useContext( UserContext ) || { agent: {} };
  // const [disableButton, setDisableButton] = useState( false );
  const [formSubmitted, isFormSubmitted] = useState( false );
  const [homeAddress, setHomeAddress] = useState( '' );
  const [addressSelected, setAddressSelected] = useState( 0 );
  const [home_inputs, setHomeInputs ] = useState( { zip: '', city: '', stateCode: '', street: '', country: '', stateName: '' }  );
  const [insuranceType, setInterestType] = useState( 'Home' );
  const [quoteData, setQuoteData] = useState( {
    cta_url: '',
    home_short_url: '',
    life_short_url: '',
    home_quote: '',
    life_quote: '',
    life_cta_url: ''
  } );

  const [addCustomer, { data, loading, error }] = useMutation( process.env.GATSBY_ENV == 'PROD' ? PROD_ADD_CUSTOMER : ADD_CUSTOMER );

  async function addToHubSpot( { client_id } ){
    let vid  = '';
    await pc.BackendService.hubSpotCheckEmail( JSON.stringify( email ) ).then( response => {
      if ( response.Payload ) {
        const data = JSON.parse( response.Payload );
        if ( data.errorType ) {
          vid = null;
        } else {
          vid = data.body.response.data.vid;
        }

        const postBody = {
          firstname: firstName,
          lastname: lastName,
          phone: phoneNo,
          email,
          agent_email: agent.email || '',
          home_type: 'SingleFamilyHome',
          dwelling_use: 'primary_fulltime_residence',
          agent_phone_number: agent.phone_no || '',
          city: home_inputs.city,
          address: home_inputs.street,
          zip: home_inputs.zip,
          client_id,
          lead_source: agent.lead_source || 'BubbleAgentPortal',
          partner_source: 'Agent Portal ' + ( agent?.company || '' ) + ' ' + ( agent?.full_name || '' ),
          full_address: homeAddress,
          state: home_inputs.stateName,
          home_quote_amount: quoteData.home_quote,
          reco_url: quoteData.cta_url || '',
          life_reco_url: quoteData.life_cta_url || '',
          interest_type: insuranceType == 'Both' ? 'Home+Life' : insuranceType,
          sakari_send_sms:  'Yes',
          send_email: 'Yes',
          short_url: quoteData.home_short_url || '',
          agent_name: agent.full_name || ( ( agent?.first_name || '' ) + ' ' + ( agent?.last_name || '' ) ),
          agent_portal_agency: agent?.company || 'Bubble Insurance'
        };
        console.log( postBody, 'postBody' );
        console.log( quoteData, 'quoteData' );
        if ( vid ) {
          postBody['vid'] = vid;
          const postBodyString = JSON.stringify( postBody );
          pc.BackendService.hubSpotUpdate( postBodyString ).then( data => {
            if ( data.Payload ) {
              console.log( data.Payload );
            }
          } );
        }

        if ( !vid ) {
          const postBodyString = JSON.stringify( postBody );
          pc.BackendService.hubSpotSync( postBodyString )
            .then( data => {
              if ( data.Payload ) {
                console.log( data.Payload );
              } }
            );
        }
      }
    }
    );
  }

  async function getQuote(){
    // getQuickQuote( {
    //   variables: {
    //     city: home_inputs.city,
    //     country: home_inputs.country,
    //     email: email, first_name:
    //     firstName, formatted_address:
    //     homeAddress,
    //     last_name: lastName,
    //     insurance_status: 'lead',
    //     phone: phoneNo,
    //     state: home_inputs.stateCode,
    //     street: home_inputs.street,
    //     zip: zipCode
    //   }
    // } ).then( res => {
    //   quoteData = {
    //     cta_url: res.data?.quickQuote?.home?.[0]?.cta_url || '',
    //     home_quote: res.data?.quickQuote?.home?.[0]?.monthly_quote_amt || 0
    //   };
    let insurance_types = insuranceType.toLowerCase();
    if( insuranceType == 'Both' ){
      insurance_types = ['home', 'life'];
    } else {
      insurance_types = [insurance_types];
    }

    const body = {
      input: {
        arg1: {
          city: home_inputs.city,
          country: home_inputs.country,
          email: email,
          first_name: firstName,
          formatted_address: homeAddress,
          last_name: lastName,
          insurance_status: 'lead',
          phone: phoneNo,
          state: home_inputs.stateCode,
          street: home_inputs.street,
          zip: zipCode
        }
      }
    };

    const isTest = process.env.GATSBY_ENV != 'PROD';

    const request = {
      fromAgentPortal: true,
      isTest,
      source: {
        lead_source
      },
      life_inputs: {
        zipcode:  body.input.arg1.zip,
        dob: '11-08-1985',
        age: '30',
        gender: 'Male',
        height: '5.10',
        weight: '160',
        smoking_status: 'no',
        term: '15',
        coverage: '500000'
      },
      home_inputs: {
        formatted_address: {
          street_address: body.input.arg1.street,
          city: body.input.arg1.city,
          state: body.input.arg1.state,
          zipcode: body.input.arg1.zip,
          country: body.input.arg1.country
        },
        full_address: body.input.arg1.formatted_address,
        is_primary_residence: 'Yes'
      },
      personal_details: {
        applicant: {
          first_name: body.input.arg1.first_name,
          middle_name: '',
          last_name:body.input.arg1.last_name,
          email: body.input.arg1.email,
          phone: body.input.arg1.phone
        },
        current_address: {
          street_address: body.input.arg1.street,
          city: body.input.arg1.city,
          state: body.input.arg1.state,
          zipcode: body.input.arg1.zip,
          country: body.input.arg1.country
        }
      }
    };

    request.type = insurance_types;

    const response = await fetch( 'https://api3.getmybubble.io/create-quote', {
      method: 'POST',
      body: JSON.stringify( request ),
      headers: {
        'content-type': 'application/json'
      }
    } );

    const data = await response.json();

    let quotes =  {
      cta_url: data?.home?.[0]?.cta_url || '',
      home_short_url: data?.home?.[0]?.home_short_url || '',
      life_short_url: data?.home?.[0]?.life_short_url || '',
      home_quote: data?.home?.[0]?.monthly_quote_amt || 0,
      life_quote: data?.life?.[0]?.monthly_quote_amt || 0,
      life_cta_url: data?.life?.[0]?.cta_url || ''
    };

    setQuoteData( quotes );

    const client_id = data?.home?.[0]?.lead_id || data?.life?.[0]?.lead_id;

    addToHubSpot( { client_id } );

    const variables =  { variables:   {
      auth0_id: agent.auth0_id,
      agent_email: agent.email,
      address: homeAddress,
      email,
      first_name: firstName,
      last_name: lastName,
      full_name: `${firstName} ${lastName}`,
      insurance_types: JSON.stringify( insurance_types ),
      phone: phoneNo,
      client_id,
      lead_source: agent.lead_source,
      city: home_inputs.city,
      stateCode: home_inputs.stateCode,
      street_address: home_inputs.street,
      cta_url: quotes.cta_url,
      home_premium: quotes.home_quote || '0',
      life_premium: quotes.life_quote || '0',
      zip: zipCode
    } };

    console.log( variables );

    addCustomer( variables ).then( ( res ) => {
      console.log( res, 'response' );
      Swal.fire( {
        text: 'New contact added successfully',
        confirmButtonColor: '#f46904',
        confirmButtonText: 'OK',
        customClass: 'swal-wide'
      } );
      setDisableButton( false );
      setFirstName( '' );
      setLastName( '' );
      setEmail( '' );
      changePhoneNo( '' );
      setZipCode( '' );
      // setInterestType( '' );
      handleClose();
      setQuoteData( {} );
      setFormattedPhone( '' );
    } );
  }

  useEffect( () => {
    if( !error && data ){
      addedContact( data.insert_agentportalcustomers_one );
    }
  }, [ data, loading, error ] );

  useEffect( () => {
    async function getAddressDetails(){
      if( homeAddress ){
        const results = await geocodeByAddress( homeAddress );
        console.log( results, 'results' );
        const { zip, city, stateCode, street, stateName, country } = getZipCode( results );
        setHomeInputs( { zip, city, stateCode, stateName, street, country } );
        setZipCode( zip ? zip : '' );
      }
    }
    getAddressDetails();
  }, [addressSelected] );

  const iconComponent = ( props ) => {
    return (
      <>
        <ExpandMoreIcon className={props.className} />
      </>

    ); };

  const menuProps = {
    className: 'select-popup'
  };

  const handleChange = ( event ) => {
    setInterestType( event.target.value );
  };

  function validateForm(){
    const phonePattern = /^[0-9]+(-[0-9]+)+$/;
    return ( phonePattern.test( phoneNo ) );
  }

  function checkEmailAvailable()
  {
    debugger;
    console.log( 'customerList',customerList );
    return  customerList.some( el => el.email.toLowerCase() === email.toLowerCase() );
  }

  async function addContact( event ) {
    debugger;
    event.currentTarget.disabled = true;
    let isValid = validateForm();
    const isEmailAvailable = await checkEmailAvailable();
    if( isEmailAvailable )
    {
      isValid = false;
      setEmailError( 'A contact with this email already exists, please use a different email address' );

    }
    debugger;
    console.log( errors );
    if ( isValid ) {
      setDisableButton( true );
      try {
        await getQuote();
      } catch {
        event.currentTarget.disabled = false;
        setDisableButton( false );
      }
    }
  }


  const [firstName, setFirstName] = useState( '' );
  const [lastName, setLastName] = useState( '' );
  const [email, setEmail] = useState( '' );
  const [zipCode, setZipCode] = useState( '' );
  const [phoneNo, changePhoneNo] = useState( '' );
  const [firstNameError, setFirstNameError] = useState( '' );
  const [lastNameError,  setLastNameError ] = useState( '' );
  const [emailError,     setEmailError    ] = useState( '' );
  const [zipCodeError,   setZipCodeError  ] = useState( '' );
  const [phoneError, setPhoneError] = useState( '' );
  const [formattedPhone, setFormattedPhone] = useState( '' );
  const [ AddressError, setAddressError ] = useState( '' );
  const [disableButton, setDisableButton] = useState( true );

  const isEnabled =
  ( phoneNo?.length == 12 ) &&
  firstName?.length > 0 &&
  lastName?.length > 0 &&
  email?.match( /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ ) &&
  zipCode?.length > 0 && !AddressError;

  const handleFirstNameChange = ( event ) => {
    const value = event.target.value;

    setFirstName( value );
    if( value.length > 200 ){
      setFirstNameError( 'Name is too long. Max 200 characters' );
    } else if ( !value.match( /^[a-zA-Z ]+$/ ) ) {
      setFirstNameError( 'Please enter valid name' );
    } else {
      setFirstNameError( '' );
    }

    // setEnabled(    phoneNo.length > 0 &&
    //   firstName.length > 0 &&
    //   lastName.length > 0 &&
    //   email.length > 0 &&
    //   zipCode.length > 0 );
  };

  const handleLastNameChange = ( event ) => {
    const value = event.target.value;

    setLastName( value );
    if( value.length > 200 ){
      setLastNameError( 'Name is too long. Max 200 characters' );
    } else if ( !event.target.value.match( /^[a-zA-Z ]+$/ ) ) {
      setLastNameError( 'Please enter valid name' );
    } else {
      setLastNameError( '' );
    }

    // setEnabled(    phoneNo.length > 0 &&
    //   firstName.length > 0 &&
    //   lastName.length > 0 &&
    //   email.length > 0 &&
    //   zipCode.length > 0 );
  };

  const handleEmailChange = ( event ) => {
    setEmail( event.target.value );
    if ( !event.target.value.match( /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ ) ) {
      setEmailError( 'Please enter valid email address' );
    } else {
      setEmailError( '' );
    }

    // setEnabled(    phoneNo.length > 0 &&
    //   firstName.length > 0 &&
    //   lastName.length > 0 &&
    //   email.length > 0 &&
    //   zipCode.length > 0 );
  };

  const handleZipCodeChange = ( event ) => {
    setZipCode( event.target.value );
    if ( !event.target.value.match( /^\d{5}$/ ) ) {
      setZipCodeError( 'Please enter valid zip-Code' );
    } else {
      setZipCodeError( '' );
    }
  };

  // function setPhoneNo( e ){
  //   debugger;
  //   const char = e.key;
  //   const regex = /[0-9]/;
  //   if ( char == 'Backspace' ) {
  //     changePhoneNo( phoneNo.substring( 0, phoneNo.length - 1 ) );
  //     // } if( !e.target.value.match( /^[0-9]+(-[0-9]+)+$/ ) ) {

  //   } else if ( regex.test( char ) ) {
  //     // if( phoneNo.length === 3 ) {
  //     //   changePhoneNo( phoneNo + '-' + char );
  //     // } else if ( phoneNo.length === 7 ) {
  //     //   changePhoneNo( phoneNo + '-' + char );
  //     // } else if( phoneNo.length == 12 ){
  //     // //
  //     // } else {
  //     //   changePhoneNo( phoneNo + char );
  //     // }
  //   }
  // }

  function setPhoneNo( e ){
    debugger;

    let count = 0;
    let phone = e.target.value.toString();
    phone = phone.replace( /^0+/, '' );
    phone = phone.replace( '+1', '' ).replace( '(', '' ).replace( ')', '' );
    let phonestring = phone.toString().slice( 0, phone.length );
    phonestring = phonestring.replace( /[^0-9]/g, '' );
    phone = phonestring.replace( /(\d{3})-?/g, function ( m, a ) {
      return ++count <= 2 ? a + '-' : m;
    } );
    phone = phone.replaceAll( '- ','-' );
    phone = phone.slice( 0, 13 );
    if ( phone.length < 12 ||  phone.length > 12 ) {
      setPhoneError( 'Please enter valid phone number' );
      changePhoneNo( phone );
    } else {
      setPhoneError( '' );
      changePhoneNo( phone );
    }
    // this.validateForm();
    let phoneNumber = phone;

    const j = phoneNumber.replace( /-/g, '' );
    var K = j.replace( /[{()}]/g, '' );
    K = K.replace( / /g, '' );
    const L = K.replace( '+1', '' );
    const A = L.substring( 0, 3 );
    const B = L.substring( 3, 6 );
    const C = L.substring( 6, 10 );
    if ( A != '' && K.length <= 3 ) {
      if ( A.length == 3 && B != '' ) {
        phoneNumber = '+1(' + A + ') ';
      } else {
        phoneNumber = '+1(' + A;
      }
    } else if ( B != '' && K.length > 3 && K.length < 7 ) {
      phoneNumber = '+1(' + A + ') ' + B;
    } else if ( B != '' && K.length >= 7 ) {
      phoneNumber = '+1(' + A + ') ' + B + '-' + C;
    } else {
      if ( L == '' ) {
        phoneNumber = '';
      }
    }
    setFormattedPhone( phoneNumber );
  }


  const handleCloseDialog = ( event, reason ) => {
    debugger;
    if ( disableBackdropClick && reason === 'backdropClick' ) {
      return false;
    }

    if ( disableEscapeKeyDown && reason === 'escapeKeyDown' ) {
      return false;
    }

    if ( !reason ) {
      setFirstName( '' );
      setLastName( '' );
      setEmail( '' );
      changePhoneNo( '' );
      setZipCode( '' );
      //setInterestType( '' );
      setQuoteData( {} );
      setFirstNameError( '' );
      setLastNameError( '' );
      setEmailError( '' );
      setZipCodeError( '' );
      setFormattedPhone( '' );
      setPhoneError( '' );
      handleClose();
    }
  };

  const invalidAddress = ( e ) => {
    debugger;
    setAddressError( e );
    // Add your logic here
  };


  return (
    <div>
      <Dialog open={open} onClose={handleCloseDialog} disableEscapeKeyDown={true} className="test">
        <DialogContent>
          <Box className='contact-box' sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <CloseIcon sx={{ position: 'absolute', top: 6, right: 6, cursor: 'pointer', fontSize: 25 }} onClick={handleCloseDialog} />

            <DialogContentText className='heading'>
          Answer a few simple questions to add new contact
            </DialogContentText>

            <CssTextField sx={{ width:'170px' }}
              type="text"
              className="input-contact"
              id="outlined-basic"
              variant="outlined"
              value={firstName}
              onChange={handleFirstNameChange}
              error={firstNameError}
              helperText={
                firstNameError
              }
              name="firstName"
              label="First Name"
              required
            />


            <CssTextField sx={{ width:'170px' }}
              type="text"
              className="input-contact"
              id="outlined-basic"
              variant="outlined"
              value={lastName}
              onChange={handleLastNameChange}
              error={lastNameError}
              helperText={
                lastNameError
              }
              name="lastName"
              label="Last Name"
              required

            />


            <CssTextField sx={{ width:'170px' }}
              type="text"
              className="input-contact"
              id="outlined-basic"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={
                emailError
              }
              name="email"
              label="E-mail"
              required

            />

            <CssTextField sx={{ width:'170px' }}
              className="input-contact"
              id="outlined-basic"
              value={formattedPhone}
              onChange={setPhoneNo}
              label= "Phone no "
              type="text"
              variant="outlined"
              error={phoneError}
              helperText={
                phoneError
              }
              required
              inputProps={{
                min: 0,
                maxLength:16,
                pattern: '[0-9]*'

              }}

            />


            <div className="input-contact">
              <div className="form-group">

                <Maps
                  type="text"
                  id="home-address"
                  name="home-address"
                  className="form-control"
                  placeholder="Home address *"
                  style={{ color:'#575656',fontSize:'14px' }}
                  helperText='Please enter valid Address'
                  newcontact={true}
                  onFocus={( e ) => {
                    if ( e.target.value === '' ) {
                      e.target.placeholder = '';
                    }
                  }}
                  getAddress={( e ) => {
                    debugger;
                    setHomeAddress( e );
                    setAddressSelected( addressSelected + 1 );

                  }}
                  onKeyPress={invalidAddress}

                />

              </div>
            </div>


            {/* <div className="input-contact">
              <div className="MuiOutlinedInput-root">

                <Maps
                  type= 'text'
                  className="input-contact"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder={'Home Address'}
                  insertLabel={true}
                  getAddress={( e ) => { setHomeAddress( e );
                    setAddressSelected( addressSelected + 1 ); }}
                  required

                />
              </div>
            </div> */}

            <CssTextField sx={{ width:'170px' }}
              type= 'text'
              className="input-contact"
              id="outlined-basic"
              variant="outlined"
              value={zipCode}
              // Only change zip from Home Address Field
              // onChange={handleZipCodeChange}
              name= 'ZipCode'
              label= 'Zip-Code'
              required
              error={zipCodeError}
              helperText={
                zipCodeError
              }
              disabled

            />


            <FormControl className='select-box' sx={{ m: 1, minWidth: 120 }}>
              <InputLabel variant="standard" htmlFor="interest-type">
                  Interest Type
              </InputLabel>

              <Select
                className='input-contact'
                variant='filled'
                id="interest-type"
                value={insuranceType}
                onChange={handleChange}
                IconComponent={iconComponent}
                MenuProps={menuProps}
                error={formSubmitted && !insuranceType}
                helperText={
                  formSubmitted && !insuranceType
                    ? 'Please select an insurance type'
                    : ''
                }
                label="Interest Type"
                inputProps={{ 'aria-label': 'Interest Type' }}
              >
                <MenuItem value='Home'>
                  <Radio
                    checked={insuranceType ===  'Home'}
                    onChange={handleChange}
                    value="Home"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'Home' }}
                  />
                Home
                </MenuItem>

                {/* <MenuItem value='Life'>
                  <Radio
                    checked={insuranceType === 'Life' }
                    onChange={handleChange}
                    value="Life"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'Life' }}
                  />
                Life
                </MenuItem>

                <MenuItem value='Both'>
                  <Radio
                    checked={insuranceType === 'Both'}
                    onChange={handleChange}
                    value="Both"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'Both' }}
                  />
                Both
                </MenuItem> */}

              </Select>
            </FormControl>


            <button
              disabled={ !isEnabled ||  firstNameError || lastNameError || emailError  || zipCodeError || phoneError || AddressError }
              className='round-button orange-button submit'
              id='addContact'
              onClick={ addContact }
              style={{
                marginBottom: '20px',
                backgroundColor: isEnabled ? ' #ff7007' : '#ff7007',
                color: isEnabled ? 'white' : 'gray',
                cursor: isEnabled ? 'pointer' : 'not-allowed'
              }}
            >
                Submit

            </button>

            {/* {isFormSubmitted && (
              <Typography className="form-submitted">Form Submitted Successfully!</Typography>
            )} */}

          </Box>
        </DialogContent>


      </Dialog>
    </div>
  );
}

