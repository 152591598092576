import '../styles/table.scss';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import  makeStyles  from '@mui/styles/makeStyles';
import Box from '@mui/system/Box';
import { navigate } from 'gatsby';
import * as React from 'react';

import { capitalizeFirstLetter } from '../helpers/capitalize';
import returnBox from './lead-status';

const useStyles = makeStyles( {
  header: {
    backgroundColor: '#F1F1F1',
    color: ( props ) => props.color
  }
} );

import { TablePagination } from '@mui/material';

import BasicPopover from './popover';


export default function CustomizedTables( props , phone, email ) {
  const classes = useStyles( props );

  function saveId( id ){
    localStorage.setItem( 'id', id );
    navigate( '/customer-profile' );
  }

  const [open, setOpen] = React.useState( {} );

  const img = {
    life: 'https://cdn.getmybubble.com/images/corporate-sites/banner-life.svg',
    auto: 'https://cdn.getmybubble.com/images/corporate-sites/banner-auto.svg',
    home: 'https://cdn.getmybubble.com/images/corporate-sites/banner-home.svg'
  };

  // Table pagination settings. Number of pages to table and number of rows per page can be set here.

  const [page, setPage] = React.useState( 0 ); // By default page.
  const [rowsPerPage, setRowsPerPage] = React.useState( 10 ); // Number of rows per page

  const handleChangePage = ( event, newPage ) => {
    debugger;
    setPage( newPage );
  };

  const handleChangeRowsPerPage = ( event ) => {
    debugger;
    if( ( props.customerList.length > rowsPerPage ) || ( props.customerList.length > event.target.value ) )
    {
      setRowsPerPage( +event.target.value );
      setPage( 0 );
    }
    else{
      setRowsPerPage( rowsPerPage );
    }

  };

  const emptyRows = rowsPerPage - Math.min( rowsPerPage, props.customerList?.length || 0 - page * rowsPerPage );

  return (

    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 900 }} aria-label="customized table" className="customtable">
          <TableHead stickyHeader aria-label="sticky table">
            <TableRow className={classes.header}>
              <TableCell className='table-header' align="center">Name</TableCell>

              <TableCell className='table-header' sx={{ display: { xs: 'none', md: 'table-cell' } }} align="center">Address</TableCell>

              <TableCell className='table-header' sx={{ display: { xs: 'none', md: 'table-cell' } }} align="center">Type</TableCell>

              <TableCell className='table-header' align="center">Premium</TableCell>

              <TableCell className='table-header' align="center">Status</TableCell>

              <TableCell className='table-header' align="center" sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>Share Via</TableCell>

              <TableCell className='table-header' align="center" sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell' } }}>Quote Viewed</TableCell>

              <TableCell className='table-header' align="center" sx={{ display: { xs: 'table-cell', sm: 'table-cell', md: 'none' } }}><p>Quote Viewed</p></TableCell>

              <TableCell className='table-header' align="center" sx={{ display: { xs: 'table-cell', sm: 'table-cell', md: 'none' } }}>Share</TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="desk" sx={{ display: { xs: 'none', sm: 'none', md: 'table-row-group', lg: 'table-row-group', xl: 'table-row-group' } }}>
            {props.customerList?.length === 0 ?
              <TableRow style={{ height: 25 * emptyRows, fontSize: 16 }}>
                <TableCell align="center" colSpan={8}>
        No Data Available
                </TableCell>
              </TableRow>
              :
              props.customerList?.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage ).map( ( row, i ) => (
                <TableRow key={i} sx={i % 2 ? { background: '#F1F1F1' } : { background: 'white' }}>

                  <TableCell className='table-row namerow' align="center" component="th" scope="row">
                    {/* <Link to="/customer-profile"> */}

                    <div onClick={() => saveId( row.id )}>
                      <p className={row.viewed ? 'verified' : 'dangerous'}>{row.full_name}</p>
                    </div>

                    {/* </Link> */}
                  </TableCell>

                  <TableCell className='table-row addressrow' sx={{ display: { xs: 'none', md: 'table-cell' } }} align="center">
                    <p>{row.address}</p>
                  </TableCell>

                  <TableCell className='table-row' sx={{ display: { xs: 'none', md: 'table-cell' } }} align="center">
                    {JSON.parse( row.insurance_types ).map( ( type, i ) => <img width="24px" className="typeimg" src={img[type?.toLowerCase()]} alt={type} key={i} />
                    )}
                  </TableCell>

                  <TableCell className='table-row' align="center">
                    <Box component="span" sx={{
                      font: '700 14px Poppins', color: '#646464'
                    }}>
                  ${row.home_premium}
                    </Box>
                /mo</TableCell>

                  <TableCell className='table-row' align="center">
                    {returnBox( row.insurance_status || 'lead' )}
                  </TableCell>

                  <TableCell className='table-row' align="center">
                    <BasicPopover email={row.email} phone={row.phone} />
                  </TableCell>

                  <TableCell className='table-row' align="center">

                    <img
                      height={'24px'}
                      width={'24px'}
                      src={`https://public-bubble.s3.us-west-2.amazonaws.com/images/${row.viewed ? 'verified' : 'dangerous'}.svg`} alt="Yes" />

                  </TableCell>
                </TableRow>
              ) )}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )} */}
          </TableBody>


          <TableBody className="mob" sx={{ display: { xs: 'table-row-group', sm: 'table-row-group', md: 'none', lg: 'none', xl: 'none' } }}>

            {props.customerList?.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage ).map( ( row, i ) => (
              <><TableRow key={i} sx={i % 2 ? { background: '#F1F1F1' } : { background: 'white' }} aria-label="expand row"
                size="small" onClick={() => setOpen( { [i]: !open[i] } )} className="cursor">

                <TableCell className='table-row namerow' align="center" component="th" scope="row">
                  {/* <Link to="/customer-profile"> */}

                  <div onClick={() => saveId( row.id )}>
                    <p className={row.viewed ? 'verified' : 'dangerous'}>{row.full_name}</p>
                  </div>

                  {/* </Link> */}
                </TableCell>

                <TableCell className='table-row' align="center">
                  <Box component="span" className="prmamt" sx={{
                    font: '700 14px Poppins', color: '#646464'
                  }}>
                  ${row.home_premium}
                  </Box>
                /mo
                </TableCell>

                <TableCell className='table-row' align="center">
                  {returnBox( row.insurance_status || 'lead' )}
                </TableCell>

                <TableCell className='table-row' sx={{ display: { xs: 'none', md: 'table-cell' } }} align="center">
                  {JSON.parse( row.insurance_types ).map( ( type, i ) => <img width="24px" className="typeimg" src={img[type?.toLowerCase()]} alt={type} key={i} />
                  )}
                </TableCell>

                <TableCell className='table-row' align="center">

                  <img
                    height={'24px'}
                    width={'24px'}
                    src={`https://public-bubble.s3.us-west-2.amazonaws.com/images/${row.viewed ? 'verified' : 'dangerous'}.svg`} alt="Yes" />

                </TableCell>


                <TableCell className='table-row' align="center">
                  <BasicPopover email={row.email} phone={row.phone}/>
                </TableCell>


              </TableRow>

              <TableRow className="collapserowtd">
                <TableCell style={{ padding: 0 }} colSpan={6}>
                  <Collapse in={open[i]} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 0 }} className="collapseshowdata">
                      <Table size="large" aria-label="">
                        <TableBody>
                          <TableRow>
                            <TableCell className='table-row namerow' align="center" component="th" scope="row">
                              <div onClick={() => saveId( row.id )}>
                                <p className={row.viewed ? 'verified' : 'dangerous'}>{row.full_name}</p>
                              </div>
                            </TableCell>

                            <TableCell className='table-row' align="center">
                              {returnBox( row.insurance_status || 'lead' )}+
                            </TableCell>

                            <TableCell>  <HighlightOffIcon className="cursor" onClick={() => setOpen( { [i]: !open[i] } )} />    </TableCell>

                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={5} className='table-row addressrow' sx={{ display: { xs: 'table-cell', md: 'table-cell' } }} align="left">
                              <p><label>Address:</label>&nbsp;&nbsp;

                                {row.address}</p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={5} className='table-row' sx={{ display: { xs: 'table-cell', md: 'table-cell' } }} align="center">
                              <p>
                                <label>Type:</label>&nbsp;&nbsp;

                                {JSON.parse( row.insurance_types ).map( ( type, i ) => ( <>

                                  <div>
                                    <img width="24px" className="typeimg" src={img[type?.toLowerCase()]} alt={type} key={i} />

                                    {capitalizeFirstLetter( type )} Insurance
                                  </div>

                                </> )
                                )}

                              </p>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell colSpan={5} className='table-row' align="center">
                              <p><label>Premium:</label>&nbsp;&nbsp;

                                <Box component="span" sx={{
                                  font: '700 14px Poppins', color: '#646464'
                                }}>

                                  ${row.home_premium}
                                </Box>
                                /mo</p>
                            </TableCell>
                          </TableRow>

                          <TableRow className="sharetd">
                            <TableCell className='table-row' align="center">
                              <div onClick={() => window.open( 'mailto:' + email, '_blank' )} className="sharebtn cursor">
                                <p><img className='pop-image' src="https://public-bubble.s3.us-west-2.amazonaws.com/images/mail.svg" alt="email" /> E-mail</p>
                              </div>

                            </TableCell>

                            <TableCell className='table-row' align="center">
                              <div onClick={() => null} className="sharebtn cursor">
                                <p><img className='pop-image' src="https://public-bubble.s3.us-west-2.amazonaws.com/images/msg.svg" alt="message" /> Text</p>
                              </div>
                            </TableCell>

                            <TableCell className='table-row' align="center">
                              <div onClick={() => window.open( 'tel:' + phone, '_blank' )} className="sharebtn cursor">
                                <p><img className='pop-image' src="https://public-bubble.s3.us-west-2.amazonaws.com/images/call.svg" alt="phone" /> Call</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow></>
            ) )}
          </TableBody>

        </Table>
      </TableContainer>

      {/* Table pagination settings. Number of pages to table and number of rows per page can be set here. */}

      <div className='paginate'>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]} //Number of rows per page can be set here.
          component="div"
          count={props.customerList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </div>
    </>
  );
}
