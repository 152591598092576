import '../styles/search.scss';
import '../styles/status.scss';
import '../styles/swal.scss';
import '../styles/mycustomer.scss';

import { gql, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// import IconButton from '@mui/material/IconButton';
// import InputBase from '@mui/material/InputBase';
// import Paper from '@mui/material/Paper';
import  Typography from '@mui/material/Typography';
import { navigate } from 'gatsby';
import * as Papa from 'papaparse';
import React, { useContext, useEffect, useRef,useState } from 'react';

import AutocompleteSearch from '../components/autocomplete';
// import Button from '../components/Button';
import ContactCreation from '../components/createCustomer';
// import CustomizedDatagrid from '../components/CustomerDatagrid';
import CustomizedTables from '../components/CustomerTable';
import Layout from '../components/layout';
import NewContactForm from '../components/new-contact';
// import Upload from '../components/upload';
import UserContext from '../context/context';

const CUSTOMERS_QUERY = gql`
query GetAgentportalcustomers($_eq: String) {
  agentportalcustomers(where: { auth0_id: {_eq: $_eq}}) {
    viewed
    address
    email
    full_name
    first_name
    last_name
    home_premium
    life_premium
    cta_url
    insurance_status
    insurance_types
    phone
    profile_url
    zip
    auth0_id
    id
  }
}`
;

const PROD_CUSTOMERS_QUERY = gql`
query GetAgentportalcustomers($_eq: String) {
  agentportalcustomers: prod_agentportalcustomers(where: { auth0_id: {_eq: $_eq}}) {
    viewed
    address
    email
    full_name
    home_premium
    life_premium
    insurance_status
    insurance_types
    phone
    cta_url
    profile_url
    zip
    auth0_id
    id
  }
}`
;

function Customers() {

  const [ customerList, getCustomers ] = useState( [] );
  // const [filter, setFilter] = useState( '' );
  // const filterChange = ( e ) => setFilter( e.target.value );
  // const [sortBy, setsortBy] = useState( '' );
  const [status, changeStatus] = useState( JSON.stringify( {
    issued: true,
    denied: true,
    application:true,
    quote: true,
    lead: true
  } ) );
  const [statusObj, changeStatusObj] = useState( JSON.parse( status ) );
  const {  agent } = useContext( UserContext ) || { agent: {} };
  const [customersToShow, updateCustomers] = useState( customerList );
  const [open, setOpen] = useState( false );
  const [lead_source, setLeadSource] = useState( '' );
  const [newContact, setContact] = useState( {} );
  const [customerName, setCustomerName] = useState( '' );

  const { loading, error, data, refetch } = useQuery( process.env.GATSBY_ENV  == 'PROD' ? PROD_CUSTOMERS_QUERY : CUSTOMERS_QUERY,  {
    variables: { _eq: agent?.auth0_id }
  } );

  const { isLoading: authLoading, isAuthenticated } = useAuth0();
  useEffect( () => {
    if( !( authLoading || isAuthenticated ) ){
      navigate( '/login' );
    }
  }, [authLoading] );

  useEffect( () => {
    setLeadSource( agent.lead_source );
    if( data ){
      const { agentportalcustomers } = data || { agentportalcustomers: [] };
      let customers =  [...agentportalcustomers].reverse();
      customers = customers.sort( ( a,b )=>b.id - a.id );
      getCustomers(  customers );
      updateCustomers( customers );
    }
  }, [loading, error, data] );

  const handleClickOpen = () => {
    setOpen( true );
  };
  const fileInput = useRef();
  const handleClose = () => {
    setOpen( false );
  };

  // const sortChange = ( e ) => setsortBy( e.target.value );

  // function filterByStatus(){
  //   const statuses = JSON.parse( status );

  //   const toShow = customerList.filter( option =>
  //     statuses[ option.insurance_status || 'lead' ]
  //   );

  //   changeStatusObj( statuses );
  //   updateCustomers( toShow );

  //   // const toShowQuote = customerList.filter( option =>
  //   //   statuses[option.quote || 'quote']
  //   // );

  //   // changeStatusObj( statuses );
  //   // updateCustomers( toShowQuote );
  // }

  async function bulkAdd(){
    const file = fileInput.current.files[0];

    const reader = new FileReader();

    if( file ){
      reader.readAsText( file );

      reader.onload = async function() {
        const customers = Papa.parse( reader.result, {
          fastMode: true,
          header: true
        } ).data;

        for ( let index = 0; index < customers.length; index++ ) {
          const element = customers[index];
          setContact( {
            ...element,
            agent,
            email: element.email,
            firstName: element.first_name,
            lastName: element.last_name,
            auth0_id: agent.auth0_id,
            phoneNo: element.phone
          } );
          await new Promise( r => setTimeout( r, 2000 ) );
        }
      };

      reader.onerror = function() {
        console.log( reader.error );
      };
    }
  }

  function addContact( contact ){
    const allCustomers = [...customerList];
    allCustomers.unshift( contact );
    getCustomers( allCustomers );
    updateCustomers( allCustomers );
    refetch();
  }

  // useEffect( filterByStatus, [status, customerList] );

  function getKeysWithTrueValues( obj ) {
    return Object.keys( obj ).filter( key => obj[key] === true );
  }

  function updateValue( value ){
    console.log( 'status',status );
    setCustomerName( value );
    let showLead = getKeysWithTrueValues( JSON.parse( status ) );
    const filteredObjects = customerList.filter( obj => showLead.includes( obj.insurance_status ) );
    if( !value ){
      updateCustomers( filteredObjects );
      return;
    }

    let toShowWithStatus = [];
    if( showLead.length ){
      toShowWithStatus = customerList.filter( option => {
        return  option.full_name === value && showLead.includes( option.insurance_status ) ;
      } );
    } else{
      toShowWithStatus = customerList.filter( option => {
        return  option.full_name === value ;
      } );
    }

    if( !showLead.length && value )
    {
      const LeadNames = getDistinctValues( toShowWithStatus, 'insurance_status' );
      addMultipleStatus( LeadNames );
    }
    updateCustomers( toShowWithStatus );
    // else{
    //   const toShowWithoutStatus = customerList.filter( option => {
    //     return option.full_name === value ;
    //   } );
    //   updateCustomers( toShowWithoutStatus );
    // }

    // const toShowQuote = customerList.filter( option => {
    //   return option.full_name === value &&  showLead.includes( option.insurance_status );
    // } );

    // if( toShowQuote ){
    //   updateCustomers( toShowQuote );
    // }
  }


  const [addStatusAll, setAddStatusAll] = useState( true );


  function getDistinctValues( array, key ) {
  // Use Set to store unique values
    const uniqueValues = new Set( array.map( item => item[key] ) );

    // Convert Set back to an array
    const distinctValues = Array.from( uniqueValues );

    return distinctValues;
  }

  function selectAll(){
    setAddStatusAll( !addStatusAll );
    const data = {};
    Object.keys( statusObj ).forEach( k => data[k] = !addStatusAll );
    changeStatus( JSON.stringify( data ) );
    changeStatusObj( data );
    updateCustomersToShow( data );
  }

  function updateCustomersToShow( data )
  {
    let showLead = getKeysWithTrueValues( data  );
    const filteredObjects = customerName ?  customerList.filter( obj => obj.full_name === customerName && showLead.includes( obj.insurance_status ) ) : customerList.filter( obj => showLead.includes( obj.insurance_status ) ) ;
    updateCustomers( filteredObjects );
  }

  function addStatus( type ){
    const statuses = Object.assign( {}, statusObj );
    if( statuses[type] ){
      statuses[type] = false;
    } else {
      statuses[type] = true;
    }
    changeStatusObj( statuses );
    changeStatus( JSON.stringify( statuses ) );
    updateCustomersToShow( statuses );
  }


  function addMultipleStatus( type )
  {
    const statuses = Object.assign( {}, statusObj );
    for ( const element of type ) {
      statuses[element] = true;
    }
    changeStatusObj( statuses );
    changeStatus( JSON.stringify( statuses ) );
    updateCustomersToShow( statuses );
  }

  return(
    <>
      {
        isAuthenticated ?
          <Layout>

            <Grid item xs={12}>
              <div className="customerdatatable">

                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    display: { xs: 'block', md: 'flex' },
                    textDecoration: 'none',
                    fontSize: '20px',
                    fontWeight: '600',
                    color:'#646464',
                    fontFamily: 'Poppins',
                    margin:'30px 0px 24px'
                  }}
                >
              Welcome back, {agent?.first_name || ''}!
                </Typography>

                <Typography
                  variant="h4"
                  noWrap
                  sx={{
                    mr: 2,
                    display: { xs: 'block', md: 'flex' },
                    textDecoration: 'none',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color:'#646464',
                    fontFamily: 'Poppins',
                    margin:'0px 0px 24px'
                  }}
                >
            My Customers
                </Typography>

                <div className="row mobflexcss">
                  <Grid item lg={6} xs={6}>
                    <div className="search-box">
                      <AutocompleteSearch
                        updateValue={updateValue}
                        customerList={customerList}/>
                    </div>
                  </Grid>

                  <Grid item xs={6} sx={{  display: { xs: 'block', sm: 'block', md: 'none' , lg: 'none' } }}>
                    <Box sx={{ display: { md: 'flex' } }} className='rect-box new-contact align-items-center justify-content-around'>
                      <div onClick={handleClickOpen}>
                        <img src="https://public-bubble.s3.us-west-2.amazonaws.com/images/person_add.svg" alt="new-contact"/>
                      </div>

                      <NewContactForm agent={agent} lead_source={lead_source} open={open} addedContact={addContact} handleClose={ handleClose }/>
                    </Box>
                  </Grid>
                </div>

                <Grid container spacing={2} sx={{ margin:'16px 0px' }} className="searchboxouter mobreactbox">
                  <Grid item xs={12} lg={8} container direction="row">

                    <div onClick={() => addStatus( 'lead' )} className={statusObj[ 'lead' ] ? 'rect-box status-lead' : 'rect-box rect-lead'}>
              Lead
                    </div>

                    <div onClick={() => addStatus( 'quote' )} className={statusObj[ 'quote'] ? 'rect-box status-quote' : 'rect-box rect-quote'}>
              Quote
                    </div>

                    <div onClick={() => addStatus( 'application' )} className={statusObj[ 'application'] ? 'rect-box status-application' : 'rect-box rect-application'}>
              Application
                    </div>

                    <div onClick={() => addStatus( 'issued' )} className={statusObj[ 'issued'] ? 'rect-box status-issued' : 'rect-box react-issued'}>
              Issued
                    </div>

                    <div onClick={() => addStatus( 'denied' )} className={statusObj[ 'denied'] ? 'rect-box status-denied' : 'rect-box react-denied'}>
              Denied
                    </div>


                    <Button onClick={() => selectAll( 'all' )} className={statusObj['all'] ? 'rect-box status-all' : 'rect-box rect-all'}
                      sx={{ display: { xs:'none', md: 'block',width:'auto' } }}>
                      { addStatusAll ? 'Unselect All' : 'Select All'}
                    </Button>

                    <Button onClick={handleClickOpen} sx={{ display: { xs:'none', md: 'flex' } }} className='rect-box new-contact align-items-center justify-content-around'>
                      <div>

                        <img src="https://public-bubble.s3.us-west-2.amazonaws.com/images/person_add.svg" alt="new-contact"/>

                        <span style={{ marginLeft:'4px' }}>Add new contact</span>
                      </div>


                    </Button>

                    <NewContactForm lead_source={lead_source} open={open} addedContact={addContact} handleClose={ handleClose } customerList={customersToShow}/>
                  </Grid>

                  {/* <Grid item lg={0.5}></Grid> */}

                  <Grid item xs={12} lg={4} className="mobspace">
                    <div className="row">
                      <Grid item lg={6} xs={6} container  sx={{ justifyContent:{ xs:'space-evenly',lg:'space-evenly' },alignItems:{ lg:'center' } }}>

                        {/* <p>Filter</p> */}

                        {/* <Paper
                      component="form"
                      className="bordercss"
                      sx={{  display: 'flex', alignItems: 'center', width: 150, height: 25 }}
                    >
                      <InputBase
                        className='filterSort-input'
                        sx={{ ml:1, flex: 1 }}
                        placeholder="Filter"
                        inputProps={{ 'aria-label': 'filter' }}
                        value={filter}
                        onChange={filterChange}
                      />

                      <IconButton sx={{ p: '10px' }} aria-label="menu">
                        <TuneIcon />
                      </IconButton>
                    </Paper> */}


                        {/* <input type="text" value={filter} onChange={filterChange} style={{ width:'60%' }}  placeholder="filter"/> */}
                      </Grid>

                      <Grid item lg={6} xs={6} container justifyContent="space-around" sx={{ justifyContent:{ xs:'space-evenly',lg:'space-around' } }}>


                        {/* <p>Sort By</p> */}

                        {/* <Paper
                      component="form"
                      className="bordercss"
                      sx={{  display: 'flex', alignItems: 'center', width: 150, height: 25 }}
                    >
                      <InputBase
                        className='filterSort-input'
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Sort by"
                        inputProps={{ 'aria-label': 'sort' }}
                        value={sortBy}
                        onChange={sortChange}
                      />

                      <IconButton sx={{ p: '10px' }} aria-label="menu">
                        <FilterListIcon />
                      </IconButton>
                    </Paper> */}


                        {/* <input type="text" value={sortBy} onChange={sortChange} style={{ width:'60%' }}  placeholder="sort"/> */}

                      </Grid>
                    </div>

                  </Grid>

                </Grid>

                <CustomizedTables customerList={customersToShow}/>

              </div>
            </Grid>

            <ContactCreation lead_source={lead_source} contactAdded={addContact} contact={newContact}/>

            {/* <div className='bulk-customers'>

          Add multiple customers

          <label htmlFor="exampleInput" className='upload' >
            Upload File
          </label>

          <input onChange={bulkAdd}  ref={fileInput} type="file" id="exampleInput" style={{ display: 'none' }} />

        </div> */}

          </Layout> :
          <></>
      }
    </>
  );
}

export default  Customers;


