import '../styles/popover.scss';
import '../styles/swal.scss';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import * as pc from 'bit-uibl-data-driver';
import React from 'react';
import Swal from 'sweetalert2';

import shareIcon from '../assets/share.svg';


export default function BasicPopover( { phone, email } ) {
  const [anchorEl, setAnchorEl] = React.useState( null );

  const handleClick = ( event ) => {
    setAnchorEl( event.currentTarget );
  };

  function firePopUp( text ){
    setAnchorEl( null );
    Swal.fire( {
      imageUrl: 'https://cdn.getmybubble.com/images/corporate-sites/images/education2.png',
      imageHeight: 151,
      imageWidth: 125,
      title: '',
      text,
      type: 'warning'
    } );

  }

  async function sendHubSpotSMS( event ){

    document.getElementById( event.target.id ).style.pointerEvents = 'none';
    await pc.BackendService.hubSpotCheckEmail( JSON.stringify( email ) ).then( response => {

      if ( response.Payload ) {
        const data = JSON.parse( response.Payload );
        const vid = data.body.response.data.vid;
        const postBody = {
          email,
          vid,
          ShareSMS: '' + new Date().getTime(),
          sakari_send_sms: 'Yes'
        };

        const postBodyString = JSON.stringify( postBody );
        pc.BackendService.hubSpotUpdate( postBodyString ).then( data => {
          if ( data.Payload ) {
            firePopUp( 'SMS has been sent' );
            console.log( data.Payload );
            document.getElementById( event.target.id ).style.pointerEvents = 'auto';
          }
        } );
      }
    } );

  }

  async function sendHubSpotEmail( event ){
    document.getElementById( event.target.id ).style.pointerEvents = 'none';
    await pc.BackendService.hubSpotCheckEmail( JSON.stringify( email ) ).then( response => {

      if ( response.Payload ) {
        const data = JSON.parse( response.Payload );
        const vid = data.body.response.data.vid;
        const postBody = {
          email,
          vid,
          ShareEmail: '' + new Date().getTime(),
          send_email: 'Yes'
        };

        const postBodyString = JSON.stringify( postBody );
        pc.BackendService.hubSpotUpdate( postBodyString ).then( data => {
          if ( data.Payload ) {
            firePopUp( 'Email has been sent' );
            document.getElementById( event.target.id ).style.pointerEvents = 'auto';
            console.log( data.Payload );
          }
        } );
      }
    } );

  }

  const handleClose = () => {
    setAnchorEl( null );
  };

  const open = Boolean( anchorEl );
  const id = open ? 'simple-popover' : undefined;

  // GetQuickQuote API related coding started here.

  function BasicPopover ( e ) {
    e.preventDefault();
  }

  return (
    <div className='popOver' >
      <Button
        aria-describedby={id} variant="contained"
        onClick={handleClick}>
        <img width={'18px'} height={'20px'} src={shareIcon} alt=""/>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >

        <div className="d-flex align-items-center justify-content-around popover-content">
          <Tooltip title="You can manually trigger SMS for your client." arrow>
            <div >

              <img className='pop-image' id="sms" onClick={sendHubSpotSMS} src="https://public-bubble.s3.us-west-2.amazonaws.com/images/SMS%20button.svg" alt="message" />
            </div>
          </Tooltip>

          <Tooltip title="You can manually trigger Email for your client." arrow>
            <div >

              <img className='pop-image' id="email" onClick={sendHubSpotEmail} src="https://cdn.getmybubble.com/images/corporate-sites/email.svg" alt="email" />
            </div>
          </Tooltip>

          <Tooltip title="You can manually contact your client." arrow>

            <div onClick={() => window.open( 'tel:' + phone, '_blank' )}>
              <img className='pop-image' src="https://cdn.getmybubble.com/images/corporate-sites/phone.svg" alt="phone" />
            </div>
          </Tooltip>

          {/* <Tooltip title="Home" arrow>
            <div>
              <img className='pop-image' src= 'https://cdn.getmybubble.com/images/corporate-sites/banner-home.svg' alt="phone" />
            </div>
          </Tooltip> */}

          {/* <div onClick={() => window.open( 'tel:' + phone, '_blank' )}> */}

          {/* <img onClick={ () => null } className='pop-image' src="https://cdn.getmybubble.com/images/corporate-sites/banner-home.svg" alt="CTA link" /> */}

        </div>


      </Popover>
    </div>
  );
}
